import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';

export default function NestedList({content, title}) {
    // const [open, setOpen] = React.useState(true);

    const open = true;
    // const handleClick = () => {
    //     setOpen(!open);
    // };


    const renderIcon = (type) => {
        switch (type) {
            case "a_telephone":
                return <PhoneIcon/>;
            case "b_whatsApp":
                return <WhatsAppIcon/>;
            case "c_email":
                return <EmailIcon/>;
            case "adresse":
                return <PlaceIcon/>;
            default:
                return null;
        }
    };

    const defineClick = (type, value) => {
        const trimmedValue = value.replace(/\s/g, '');

        switch (type) {
            case "a_telephone":
                return `tel:${trimmedValue}`
            case "b_whatsApp":
                return `whatsapp://send?phone=${trimmedValue}`
            case "c_email":
                return `mailto:${trimmedValue}`
            case "adresse":
                return "";
            default:
                return null;
        }
    }

    return (    <>
            <List
                sx={{
                    width: "100%",
                }}
            >
                <ListItemButton
                    sx={{
                        color: '#ffffff',
                        bgcolor: '#183B1F',
                        borderRadius: '8px',
                        '&:hover': {
                            bgcolor: '#276133',
                        },
                    }}
                    // onClick={handleClick}
                >
                    <ListItemText primary={title} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </List>
            <Collapse sx={{ color: '#ffffff', }} in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {(() => {
                        const listItems = [];
                        for (let index = 0; index < content.length; index++) {
                            const contact = content[index].attributes;
                            console.log(contact, 'contact');
                            listItems.push(
                                <ListItemButton key={index} sx={{ pl: 4 }}>
                                    <ListItemIcon sx={{ color: '#ffffff' }}>
                                        {renderIcon(contact.Type)}
                                    </ListItemIcon>
                                    {contact.Type === 'adresse' ? (
                                        <ListItemText disabled primary={contact.Value} />
                                    ) : (
                                        <ListItemText
                                            onClick={() => window.open(defineClick(contact.Type, contact.Value))}
                                            primary={contact.Value}
                                        />
                                    )}
                                </ListItemButton>
                            );
                        }
                        return listItems;
                    })()}
                </List>
            </Collapse>
        </>
    );
}
