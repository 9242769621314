import {Link, Route, Routes} from "react-router-dom";
import Cdv from "./Route/cdv";

export default function App() {
    return (
        <>
            <Routes>
                    <Route index element={<Cdv/>}/>
                    <Route path="*" element={<NoMatch/>}/>
            </Routes>
        </>);
}

function NoMatch() {
    return (<div>
            <h2>Mauvaise URL !</h2>
            <p>
                <Link to="/">Retourner à la carte de visite</Link>
            </p>
        </div>);
}
