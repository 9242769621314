import '../style.scss'; // path to your SCSS file
import logo from '../logo.svg'

import NestedList from "../component/accordion";
import {Button} from "@mui/material";
import axios from "axios";
import {useEffect, useState} from "react";

function Cdv() {
    // const currentURL = window.location.href;

    // const [error, setError] = useState(null);
    const [owner, setOwner] = useState([]);
    const [contact, setContact] = useState([]);
    // const [localisation, setLocalisation] = useState([]);
    // const [email, setEmail] = useState("");

    axios.defaults.headers.common['Authorization'] = `Bearer d209c3b1df2cb574dd4b87f035da95373ad5095ed8331e33c55df4db0cabfd2218f9cc716090c2b0c76f66defa9a91646f73906e070014e3014c3e9b9ae1b60ff2d0b366d6c62ba896b955beddede8a519ff8304c1b4be77c37b889340edd14f0c5cd0f016d5e0ae58e4c772aaf3a3be86f0ac763ab804f41024c569efa5d698`;

    useEffect(() => {
        axios.get("https://api.cdv.innovationconseil.fr/api/card-owners?" +
            "populate[contacts][fields][0]=Type&" +
            "populate[contacts][fields][1]=Value&" +
            "populate[contacts][sort][0]=Type:asc&" +
            "populate[localisations][fields][0]=Type&" +
            "populate[localisations][fields][1]=Value&" +
            "populate[localisations][sort][0]=Value:asc&" +
            "fields[0]=First_Name&" +
            "fields[1]=Last_Name&" +
            "fields[2]=Poste&" +
            "publicationState=live&" +
            "locale[0]=en")
            .then((response) => {

                // console.log(response.data);
                const owner = response.data.data[0].attributes;
                setOwner(owner)

                const contact = owner.contacts.data;
                setContact(contact)

                // const localisation = owner.localisations.data;
                // setLocalisation(localisation);
                //
            })
            .catch((error) => console.log(error));
    }, []);

    console.log(owner);

    return (
        <div className="container">
            <div className="content">
                <img src={logo} alt="logo de CADE" height="100"/>
                <div className="avatar-card">
                    {/*<Avatar sx={{width: 64, height: 64}}>A M</Avatar>*/}
                    <h2>
                        <span>{owner.First_Name}</span>
                        <br/>
                        <span>{owner.Last_Name}</span>
                    </h2>
                    <h3>{owner.Poste}</h3>
                </div>
                <div className="list">
                    <NestedList content={contact} title={"Contact"}/>
                    {/*<NestedList content={localisation} title={"Localisation"}/>*/}
                </div>
                <Button variant="contained" sx={{
                    color: '#ffffff',
                    bgcolor: '#183B1F',
                    '&:hover': {
                        bgcolor: '#276133',
                    },
                    width: "300px",
                    borderRadius: '8px',
                }} onClick={() => window.open("https://cade-internationale.com", "_blank")}>Visiter notre site
                    internet</Button>

                {/*<QRCode*/}
                {/*    style={{*/}
                {/*        height: "256px",*/}
                {/*        maxWidth: "100%",*/}
                {/*        width: "100%",*/}
                {/*    }}*/}
                {/*    bgColor="#ffffff"*/}
                {/*    fgColor="#000000"*/}
                {/*    value={currentURL}*/}
                {/*    level="L"*/}
                {/*/>*/}

            </div>
        </div>
    );
}

export default Cdv;
